import { useDaily, useDevices, useLocalSessionId, useMeetingSessionState, useMeetingState, useNetwork, useParticipantIds, useRecording, useScreenShare, useWaitingParticipants } from "@daily-co/daily-react";
import { Excalidraw } from "@excalidraw/excalidraw";
import { isSupported } from "firebase/messaging";
import { Howl } from "howler";
import { useContext, useEffect, useRef, useState } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import api from "../../api";
import { ReactComponent as BackgroundEffectsIcon } from "../../assets/images/icons/ic-background-effects.svg";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as NotificationActiveIcon } from "../../assets/images/icons/ic-notification-active.svg";
import { ReactComponent as PinIcon } from "../../assets/images/icons/ic-pin.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/icons/ic-close.svg";
import UserPlaceholder from "../../assets/images/placeholders/user-placeholder.png";
import { palette } from "../../common/constants";
import { Each } from "../../common/Each";
import MainContext from "../../common/MainContext";
import MultiStateSwitch from "../../components/MultiStateSwitch";
import typo from "../../typography.module.css";
import Button from "../Button";
import AlertDialog from "../dialogs/AlertDialog";
import LiveBadge from "../LiveBadge";
import Slider from "../Slider";
import Toggle from "../Toggle";
import DailyChat from "./DailyChat";
import DailyControls from "./DailyControls";
import DailyParticipant from "./DailyParticipant";
import styles from "./DailyRoom.module.css";
import DailySpotlighted from "./DailySpotlighted";
import PDFCanvas from "./PDFCanvas";
import TooltipWrapper from "../TooltipWrapper";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";
import DropdownSelection from "../DropdownSelection";
import NotificationBanner from "../NotificationBanner";

const DailyRoom = ({
    token,
    roomName,
    students,
    onStudentsChange,
    websocket,
    whiteboard,
    onWhiteboardChange,
    onCallRejoin,
    lesson_id,
    screenSharingRequests,
    onScreenSharingRequestChange }) => {

    const context = useContext(MainContext)
    const call = useDaily()
    const devices = useDevices()
    const localSessionId = useLocalSessionId()
    const participants = useParticipantIds({ filter: 'remote' })
    const handRaisedParticipants = useParticipantIds({ filter: (p) => { return p?.userData?.handRaised } })
    const { waitingParticipants, grantAccess } = useWaitingParticipants()
    const { data: sessionData } = useMeetingSessionState()
    const meetingState = useMeetingState()
    const fullScreenHandle = useFullScreenHandle()
    const { threshold } = useNetwork()
    const { width } = useWindowDimensions()

    const [waitingList, setWaitingList] = useState([])
    const [showParticipants, setShowParticipants] = useState(false)
    const [fullScreen, setFullScreen] = useState(false)
    const [excalidrawAPI, setExcalidrawAPI] = useState(null)
    const [chat, setChat] = useState(false)
    const [unread, setUnread] = useState(0)
    const [file, setFile] = useState(null);
    const [roomInfo, setRoomInfo] = useState(null)
    const [lockLoading, setLockLoading] = useState(false)
    const [overlayMenu, setOverlayMenu] = useState({ open: false, mode: null })
    const [whiteboardController, setWhiteboardController] = useState(null)
    const [endingCall, setEndingCall] = useState(false)
    const [muted, setMuted] = useState(false)
    const [listTab, setListTab] = useState(0)
    const [chatSounds, setChatSounds] = useState(true)
    const [chatNotifications, setChatNotifications] = useState(true)
    const [backgroundBlur, setBackgroundBlur] = useState(false)
    const [blurStrength, setBlurStrength] = useState(50)
    const [backgroundImageSet, setBackgroundImageSet] = useState(false)
    const [backgroundImage, setBackgroundImage] = useState(null)

    const [spotlighted, _setSpotlighted] = useState([])
    const spotlightedRef = useRef(spotlighted)
    const setSpotlighted = (data) => {
        spotlightedRef.current = data
        _setSpotlighted(data)
    }

    const [pinned, _setPinned] = useState([])
    const pinnedRef = useRef(pinned)
    const setPinned = (data) => {
        pinnedRef.current = data
        _setPinned(data)
    }

    const backgrounds = [
        {
            name: 'Conference Room',
            url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/conference%20room.png'
        },
        {
            name: 'Valencia',
            url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/valencia.png'
        },
        // {
        //     name: 'Orange Chair',
        //     url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/orange%20chair.png'
        // },
        {
            name: 'Living Room',
            url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/living%20room.png'
        },
        {
            name: 'Trendy Office',
            url: 'https://starting-finance-dev.fra1.cdn.digitaloceanspaces.com/backgrounds/trendy%20office.png'
        },
    ]

    const dropdownSelectionOptions = [
        {
            id: 0,
            label: 'Presenti'
        },
        {
            id: 1,
            label: 'Assenti'
        },
        {
            id: 2,
            label: 'In attesa'
        },
        {
            id: 3,
            label: 'Richieste'
        }
    ]

    const waitingSound = new Howl({
        src: ['/sounds/waiting.mp3'],
        autoplay: false,
        loop: false,
        volume: 0.5,
    });

    useEffect(() => {
        if (devices.cameras.length > 0) {
            if (!devices.currentCam) {
                devices.setCamera(devices.cameras[0].device.deviceId)
            }
        }
        if (devices.speakers.length > 0) {
            if (!devices.currentSpeaker) {
                devices.setSpeaker(devices.speakers[0].device.deviceId)
            }
        }
        if (devices.microphones.length > 0) {
            if (!devices.currentMic) {
                devices.setMicrophone(devices.microphones[0].device.deviceId)
            }
        }
    }, [devices])

    const [remoteAudioLevels, setRemoteAudioLevels] = useState({})

    useEffect(() => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            .catch(error => {
                console.error(error)
            })

        document.addEventListener(`lessons/${roomName}-screen-sharing-request`, () => {
            setOverlayMenu({ open: true, mode: 'list' })
            setListMode('screen_sharing_requests')
            setListTab(3)
        })

        call.on('participant-left', (e) => {
            setPinned(pinnedRef.current.filter(p => p !== e.participant.session_id))
            setSpotlighted(spotlightedRef.current.filter(s => s.session_id !== e.participant.session_id))
        })

        const startRemoteAudioObserver = async () => {
            try {
                if (!call.isRemoteParticipantsAudioLevelObserverRunning()) {
                    await call.startRemoteParticipantsAudioLevelObserver(100);
                }
            } catch (e) {
                console.error("Error starting audio observer: ", e)
            }
        }

        startRemoteAudioObserver()

        call.on('remote-participants-audio-level', async (event) => {
            setRemoteAudioLevels(event.participantsAudioLevel)
        })

    }, [])

    useEffect(() => {
        sortParticipants()
    }, [remoteAudioLevels])

    useEffect(() => {
        const getSessionData = async () => {
            try {
                let response = await api.get(`/lessons/${roomName}/session-data`)
                if (response.data) {
                    setPinned(response.data.pinned ?? [])
                }
            }
            catch (e) {
                console.error(e)
            }
        }
        if (sessionData) {
            setPinned(sessionData?.pinned ?? [])
            if (sessionData.pinned && sessionData.pinned.length > 0) {
                setSpotlighted([...sessionData.pinned.map(p => { return { session_id: p, pinned: true } })])
            }
            else {
                setSpotlighted([{ session_id: localSessionId, pinned: false }])
            }
        }
        else {
            getSessionData()
        }
    }, [sessionData, localSessionId, roomName])

    useEffect(() => {
        if (pinned && pinned.length > 0) {
            let all = Object.values(call.participants())
            let pinnedXSpotlighted = pinned.filter(p => all.find(a => a.session_id === p))
            setSpotlighted(pinnedXSpotlighted.map(p => { return { session_id: all.find(a => a.session_id === p).session_id, pinned: true } }))

            if (pinnedXSpotlighted.length > 0) {
                call.setMeetingSessionData({ pinned: pinnedXSpotlighted })
            }
            else {
                setSpotlighted([{ session_id: localSessionId, pinned: false }])
            }
        }
        else {
            setSpotlighted([{ session_id: localSessionId, pinned: false }])
        }
    }, [pinned])

    useEffect(() => {
        if (excalidrawAPI) {
            if (whiteboard.show && !whiteboard.readOnly) {
                websocket.sendMessage({
                    type: 'whiteboard_started',
                    data: [],
                    session_id: localSessionId
                })
                if (call) {
                    const canvas = document.querySelector('.excalidraw__canvas.static')
                    if (canvas) {
                        const stream = canvas.captureStream(60)
                        call.startCustomTrack({ track: stream.getTracks()[0], trackName: 'whiteboard' })
                    }
                }
            }

            if (!whiteboard.show && !whiteboard.readOnly) {
                websocket.sendMessage({
                    type: 'whiteboard_stopped'
                })
                if (call && !['error', 'left-meeting'].includes(meetingState)) {
                    call.stopCustomTrack('whiteboard')
                }
            }
            excalidrawAPI.updateScene({ elements: whiteboard.data })
        }

        if (whiteboard.show && whiteboard.readOnly && whiteboard.sessionId && call) {
            let participants = call.participants()
            const p = participants[whiteboard.sessionId]
            if (p) {
                setWhiteboardController(`${p.userData?.name} ${p.userData?.surname}`)
            }
        }

    }, [whiteboard])

    useEffect(() => {
        let all = call.participants()

        let participantArray = Object.values(all)

        let newStudents = students.map(s => {
            s.present = false
            for (let p of participantArray) {
                if (s.id === p.userData?.id && !p.owner) {
                    s.sessionId = p.session_id
                    s.present = true
                }
            }
            return s
        })
        onStudentsChange(newStudents)
    }, [participants])

    useEffect(() => {
        if (waitingParticipants) {
            const wList = [...waitingParticipants.map(w => {
                return { name: w.name, sessionId: w.id }
            })]
            if (wList.length > 0) {
                let name = wList.length === 1 ? `${wList[0].name} è in attesa di entrare` : 'Alcuni studenti sono in attesa di entrare'
                sendWaitingNotification(name, 'Vai a vedere in app.')
                waitingSound.play()
                try {
                    context.setNotificationBanner({
                        title: name,
                        body: "Clicca qui e apri il menù lista d'attesa per accettare.",
                        onClick: () => {
                            setOverlayMenu({ open: true, mode: 'list' })
                            setListMode('waiting')
                            setListTab(2)
                        }
                    })
                }
                catch (e) {
                    console.error(e)
                }
            }
            setWaitingList(wList)
        }
    }, [waitingParticipants])

    useEffect(() => {
        if (roomName) {
            getRoomInfo(roomName)
        }
    }, [roomName])

    useEffect(() => {
        if (['error', 'left-meeting'].includes(meetingState)) {
            onWhiteboardChange(null, null, true)
            setOverlayMenu({ open: false, mode: null })
            setShowParticipants(false)
            setChat(false)
        }
    }, [meetingState])


    const [sortedParticipants, setSortedParticipants] = useState([])
    const sortParticipants = () => {
        let all = call.participants();
        const sortedParticipants = [...participants].sort((a, b) => {
            const aData = all[a];
            const bData = all[b];

            const aIsOwner = aData?.owner === true;
            const bIsOwner = bData?.owner === true;

            // Priorità basata su `owner`
            if (aIsOwner && !bIsOwner) return -1; // a prima di b
            if (!aIsOwner && bIsOwner) return 1;  // b prima di a

            // Se entrambi sono owner, priorità basata su `role`
            if (aIsOwner && bIsOwner) {
                const aRoleIsTeacher = aData?.userData?.role === "teacher";
                const bRoleIsTeacher = bData?.userData?.role === "teacher";

                if (aRoleIsTeacher && !bRoleIsTeacher) return -1; // a (teacher) prima di b
                if (!aRoleIsTeacher && bRoleIsTeacher) return 1;  // b (teacher) prima di a

                return 0;
            }

            // Priorità basata su `audio level` (solo se maggiore di 0)
            const aAudioLevel = remoteAudioLevels[a] || 0;
            const bAudioLevel = remoteAudioLevels[b] || 0;

            if (aAudioLevel > 0 && bAudioLevel === 0) return -1; // a (audio level > 0) prima di b
            if (aAudioLevel === 0 && bAudioLevel > 0) return 1;  // b (audio level > 0) prima di a

            if(aAudioLevel > 0 && bAudioLevel > 0) {
                return 1
            }

            // Se entrambi hanno lo stesso `audio level`, priorità basata su `handRaised`
            const aHandRaised = aData?.userData?.handRaised === true;
            const bHandRaised = bData?.userData?.handRaised === true;

            if (aHandRaised && !bHandRaised) return -1; // a (handRaised: true) prima di b
            if (!aHandRaised && bHandRaised) return 1;

            return 0;
        });

        setSortedParticipants(sortedParticipants);
    };

    useEffect(() => {
        console.log(remoteAudioLevels)
    }, [remoteAudioLevels])


    const getInitials = (input) => {
        // Dividi la stringa in parole usando lo spazio come delimitatore
        const words = input.split(' ');

        // Prendi la prima lettera di ciascuna parola e uniscile
        const initials = words.map(word => word.charAt(0)).join('');

        // Restituisci la stringa di iniziali
        return initials.slice(0, 2);
    }

    const getRoomInfo = async (roomName) => {
        try {
            let roomInfo = await api.get(`/teacher/rooms/${roomName}`)
            setRoomInfo(roomInfo)
        }
        catch (e) {
            console.error(e)
        }
    }

    const lockRoom = async (locked) => {
        setLockLoading(true)
        try {
            const body = { locked: locked }
            await api.put(`teacher/rooms/${roomName}/lock`, body)
            getRoomInfo(roomName)
        }
        catch (e) {
            console.error(e)
        }
        setLockLoading(false)
    }

    const onFullScreenChange = (fullScreenState) => {
        setFullScreen(fullScreenState)
    }

    const onWhiteboardDataChange = async (event) => {
        if (!whiteboard.readOnly) {
            websocket.sendMessage({
                type: 'whiteboard_changed',
                data: event,
                session_id: localSessionId
            })
        }
    }

    const onRecordingStarted = async (event) => {
        try {
            context.setNotificationBanner({
                title: "🎥 Registrazione Cloud Iniziata",
                body: "La registrazione è iniziata"
            })
        }
        catch (e) {
            console.error(e)
        }
    }

    const onRecordingError = async (event) => {
        try {
            document.dispatchEvent(new CustomEvent('api-error', { detail: { title: "Errore Registrazione Cloud", message: event?.errorMsg ?? '' } }));
            startRecording({
                layout: {
                    preset: "active-participant"
                }
            })
        }
        catch (e) {
            console.error(e)
        }
    }

    const onRecordingStopped = async (event) => {
        try {
            context.setNotificationBanner({
                title: "Registrazione Cloud Terminata",
                body: event.errorMsg
            })
            startRecording({
                layout: {
                    preset: "active-participant"
                }
            })
        } catch (e) {
            console.error(e)
        }
    }

    const { isRecording, startRecording } = useRecording({ onRecordingStarted: onRecordingStarted, onRecordingError: onRecordingError, onRecordingStopped: onRecordingStopped })

    function muteAll() {
        let updateList = {};
        for (let id in call.participants()) {
            if (id === 'local') {
                continue;
            }
            updateList[id] = { setAudio: false };
        }
        call.updateParticipants(updateList);
    }

    const [listMode, setListMode] = useState('presents')
    const onListStateChanged = (index) => {
        setListTab(index)
        switch (index) {
            case 0:
                setListMode('presents')
                break
            case 1:
                setListMode('absents')
                break
            case 2:
                setListMode('waiting')
                break
            case 3:
                setListMode('screen_sharing_requests')
                break
            default:
                return
        }
    }


    const notifyAbsents = async (absents) => {
        try {
            await api.post(`/teacher/rooms/${roomName}/notify-absents`, { absents: absents })
            let newStudents = students.map(s => {
                for (let a of absents) {
                    if (s.id === a) {
                        s.notified = true
                    }
                }
                return s
            })
            onStudentsChange(newStudents)
        }
        catch (e) {
            console.error(e)
        }
    }

    const sendWaitingNotification = (title, message) => {
        if (isSupported()) {
            if (Notification.permission === 'granted') {
                new Notification(title, {
                    body: message,
                });
            } else {
                console.error('Non hai il permesso per inviare notifiche.');
            }
        } else {
            console.error('Notifiche non supportate.');

        }
    };

    return (
        <FullScreen handle={fullScreenHandle} onChange={onFullScreenChange}>
            <div className={styles.container}>
                {fullScreen &&
                    <NotificationBanner notification={context.notificationBanner} />
                }
                <div className={`${styles.main} ${fullScreen ? styles.fullscreen : ''}`}>
                    {/* --------------------------- Spotlighted Stream --------------------------- */}
                    <div className={styles.spotlighted}>
                        {!['error', 'left-meeting'].includes(meetingState) &&
                            <>
                                {spotlighted && !whiteboard.show && !file &&
                                    <DailySpotlighted
                                        pinned={pinned}
                                        spotlighted={spotlighted}
                                        onRemoveSpotlighetd={(session_id) => {
                                            setSpotlighted(spotlighted.filter(s => s.session_id !== session_id))
                                        }} />
                                }
                                {whiteboard.show && !file &&
                                    <div style={{ display: 'flex', minHeight: '500px', width: '100%', position: 'relative' }}>
                                        {whiteboardController && whiteboard.readOnly &&
                                            <div className={styles.whiteboardName}>{whiteboardController}</div>
                                        }
                                        <Excalidraw viewModeEnabled={whiteboard.readOnly} onChange={onWhiteboardDataChange} excalidrawAPI={(api) => setExcalidrawAPI(api)} />
                                    </div>
                                }
                                {file &&
                                    <PDFCanvas file={file} />
                                }

                                {/* {sessionData?.spotlighted === spotlighted &&
                                    <div className={styles.pinBadge}>
                                        <PinIcon />
                                        Fissato
                                    </div>
                                } */}

                                {isRecording &&
                                    <div style={{ display: 'flex', position: 'absolute', top: '.25rem', right: '.25rem', background: 'rgba(0,0,0,0.6)', borderRadius: '8px' }}>
                                        <LiveBadge label={'REC'} />
                                    </div>
                                }

                                {['low', 'very-low'].includes(threshold) &&
                                    <div className={styles.overlay}>
                                        {threshold === 'low' &&
                                            <div>
                                                La qualità della tua connessione è scarsa...
                                            </div>
                                        }
                                        {threshold === 'very-low' &&
                                            <div>
                                                La qualità della tua connessione è molto scarsa...
                                            </div>
                                        }
                                    </div>
                                }
                            </>
                        }
                        {meetingState === 'left-meeting' &&
                            <div className={styles.meetingLeft}>
                                Hai abbandonato la lezione.
                                <Button onClick={() => {
                                    onCallRejoin()
                                }}>RIENTRA</Button>
                            </div>
                        }
                        {meetingState === 'error' &&
                            <div className={styles.meetingLeft}>
                                Non sei più all'interno della lezione. È possibile che un altro docente o tutor abbia terminato la lezione.
                                <Button onClick={() => {
                                    onCallRejoin()
                                }}>RIENTRA</Button>
                                <Button onClick={() => { window.location.href = '/' }}>TORNA ALLA DASHBOARD</Button>
                            </div>
                        }
                    </div>

                    {/* ---------------------------------- Chat ---------------------------------- */}
                    <DailyChat token={token} open={chat} unread={{ state: unread, setState: setUnread }} lesson_id={lesson_id} sounds={chatSounds} notifications={chatNotifications} />

                    {overlayMenu.open &&
                        <>
                            {overlayMenu.mode === 'settings' &&
                                < div className={styles.settings}>
                                    <div className={styles.settingsHeader}>
                                        <div className={typo.subtitle}>Impostazioni</div>
                                    </div>
                                    <div className={typo.body} style={{ opacity: 0.7 }}>Gestisci le impostazioni della stanza</div>
                                    <div className={styles.settingsContent}>
                                        <div className={styles.settingsRow}>
                                            <div className={styles.settingsRowInner}>
                                                <div className={styles.settingsTitle}>ID</div>
                                                {roomInfo?.name}
                                            </div>
                                        </div>
                                        <div className={styles.settingsRow}>
                                            <div className={styles.settingsRowInner}>
                                                <div className={styles.settingsTitle}>Bloccata</div>
                                                <Toggle checked={roomInfo?.privacy === 'private'} loading={lockLoading} onChange={(value) => {
                                                    lockRoom(value)
                                                }} />
                                            </div>
                                            <div className={styles.settingsRowInner}>
                                                <div className={typo.caption}>
                                                    Quando la stanza è bloccata gli studenti dovranno essere sempre approvati manualmente da un docente o da un tutor.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className={typo.subheadline}>Audio</div> */}
                                        <div className={styles.settingsRow}>
                                            <div className={styles.settingsRowInner}>
                                                <div className={styles.settingsTitle}>Notifiche Chat</div>
                                                <Toggle checked={chatNotifications} loading={false} onChange={(value) => {
                                                    setChatNotifications(value)
                                                }} />
                                            </div>
                                            <div className={styles.settingsRowInner}>
                                                <div className={typo.caption}>
                                                    Ricevi una notifica desktop per i messaggi della chat e le alzate di mano.
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.settingsRow}>
                                            <div className={styles.settingsRowInner}>
                                                <div className={styles.settingsTitle}>Suoni Chat</div>
                                                <Toggle checked={chatSounds} loading={false} onChange={(value) => {
                                                    setChatSounds(value)
                                                }} />
                                            </div>
                                        </div>
                                        <div className={styles.settingsRow}>
                                            <div className={styles.settingsRowInner}>
                                                <div className={styles.settingsTitle}>Immagine Sfondo</div>
                                                <Toggle checked={backgroundBlur} loading={false} onChange={(value) => {
                                                    if (value) {
                                                        setBackgroundImageSet(true)
                                                    } else {
                                                        call.updateInputSettings({
                                                            video: {
                                                                processor: {
                                                                    type: 'none',
                                                                },
                                                            },
                                                        });
                                                        setBackgroundImageSet(false)
                                                    }
                                                }} />
                                            </div>
                                            {backgroundImageSet &&
                                                <div className={styles.settingsRowInner}>
                                                    <div className={styles.backgrounds}>
                                                        <Each of={backgrounds} render={(bg, index) => {
                                                            return (
                                                                <img className={`${styles.background} ${bg.url === backgroundImage ? styles.selected : ''}`} src={bg.url} alt="" onClick={() => {
                                                                    setBackgroundImage(bg.url)
                                                                    call.updateInputSettings({
                                                                        video: {
                                                                            processor: {
                                                                                type: 'background-image',
                                                                                config: {
                                                                                    source: bg.url,
                                                                                },
                                                                            },
                                                                        },
                                                                    });
                                                                }} />
                                                            )
                                                        }} />
                                                    </div>
                                                </div>
                                            }
                                            <div className={styles.settingsRowInner}>
                                                <div className={typo.caption}>
                                                    Imposta un'immagine come sfondo del tuo video.
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.settingsRow}>
                                            <div className={styles.settingsRowInner}>
                                                <div className={styles.settingsTitle}>Sfocatura Sfondo</div>
                                                <Toggle checked={backgroundBlur} loading={false} onChange={(value) => {
                                                    setBackgroundBlur(value)
                                                    call.updateInputSettings({
                                                        video: {
                                                            processor: {
                                                                type: value ? 'background-blur' : 'none',
                                                                config: { strength: value ? (blurStrength > 0 ? blurStrength : 1) / 100 : 0 },
                                                            },
                                                        },
                                                    });
                                                }} />
                                            </div>
                                            {backgroundBlur &&
                                                <div className={styles.settingsRowInner}>
                                                    <Slider icon={<BackgroundEffectsIcon />} level={blurStrength} onChange={(value) => {
                                                        setBlurStrength(value < 1 ? 1 : value)
                                                        call.updateInputSettings({
                                                            video: {
                                                                processor: {
                                                                    type: 'background-blur',
                                                                    config: { strength: (value < 1 ? 1 : value) / 100 },
                                                                },
                                                            },
                                                        });
                                                    }} />
                                                </div>
                                            }
                                            <div className={styles.settingsRowInner}>
                                                <div className={typo.caption}>
                                                    Sfoca lo sfondo del tuo video per una maggiore privacy.
                                                </div>
                                            </div>
                                        </div>
                                        <div className={styles.settingsRow}>

                                            <Button
                                                fullWidth
                                                appearance="default"
                                                inverse
                                                accentColor={'#FDB323'}
                                                onClick={() => {
                                                    muteAll()
                                                }}>MUTA TUTTI <MicOffIcon /></Button>
                                        </div>
                                        <div className={styles.settingsRow}>
                                            <Button
                                                fullWidth
                                                onClick={() => {
                                                    setEndingCall(true)
                                                }}
                                                appearance="default"
                                                inverse
                                                accentColor={'#F43A3A'}
                                                style={{ padding: '.5rem', fontSize: '1rem' }}>
                                                TERMINA LEZIONE
                                            </Button>
                                        </div>


                                    </div>
                                </div>
                            }
                            {overlayMenu.mode === 'list' &&
                                <div className={styles.list}>
                                    {width > 540 &&
                                        <MultiStateSwitch selected={listTab} states={['PRESENTI', 'ASSENTI', waitingList.length > 0 ? `IN ATTESA (${waitingList.length})` : 'IN ATTESA', screenSharingRequests.length > 0 ? `RICHIESTE (${screenSharingRequests.length})` : 'RICHIESTE']} onStateChange={onListStateChanged} style={{ '--not-selected-color': 'rgba(255,255,255,0.72)' }} />
                                    }
                                    {width <= 540 &&
                                        <DropdownSelection appereance="transparent" options={dropdownSelectionOptions} defaultOptionIndex={listTab} onSelect={(value) => {
                                            onListStateChanged(value)
                                        }} />
                                    }
                                    {listMode === 'presents' &&
                                        <div className={styles.presents}>
                                            <div className={styles.presentsHeader}>
                                                <div className={typo.subheadline}>Presenti</div>
                                                <div className={typo.subheadline}>{students.filter(s => s.present).length}</div>
                                            </div>
                                            <div className={styles.presentsParticipants}>
                                                <Each of={students.filter(s => s.present).sort((a, b) => {
                                                    return a.surname - b.surname
                                                })} render={(participant, index) => {
                                                    return (
                                                        <DailyParticipant
                                                            key={participant.sessionId}
                                                            pinned={pinned}
                                                            session_id={participant.sessionId}
                                                            appearance="list"
                                                            activateAudio={false}
                                                            onPin={(session_id) => {
                                                                if (!pinned?.includes(session_id) && pinned?.length < 4) {
                                                                    setPinned([...pinned, session_id])
                                                                    call.setMeetingSessionData({ pinned: [...pinned, session_id] })
                                                                    setSpotlighted([...spotlighted, { session_id: session_id, pinned: true }])
                                                                }
                                                                else {
                                                                    setPinned(pinned.filter(p => p !== session_id))
                                                                    call.setMeetingSessionData({ pinned: pinned.filter(p => p !== session_id) })
                                                                    setSpotlighted(spotlighted.map(s => {
                                                                        return { session_id: s.session_id, pinned: s.session_id === session_id ? false : s.pinned }
                                                                    }))

                                                                }
                                                            }}
                                                        />
                                                    )
                                                }} />
                                            </div>
                                        </div>
                                    }
                                    {listMode === 'absents' &&
                                        <div className={styles.absents}>
                                            <div className={styles.absentsHeader}>
                                                <div className={typo.subheadline}>Assenti</div>
                                                <div className={typo.subheadline}>{students.filter(s => !s.present).length}</div>
                                            </div>
                                            <div className={styles.absentsParticipants}>
                                                <Each of={students.filter(s => !s.present).sort((a, b) => {
                                                    return a.surname - b.surname
                                                })} render={(absent, index) => {
                                                    return (
                                                        <div className={styles.absentParticipant}>
                                                            <img src={absent.picture ?? UserPlaceholder} alt="" className={styles.presentAvatar} />
                                                            <div className={styles.participantName}>{`${absent.surname} ${absent.name}`}</div>
                                                            <Button disabled={absent.notified} appearance="default" fullWidth accentColor={'#FDB323'} onClick={async () => {
                                                                await notifyAbsents([absent.id])
                                                            }} style={{ padding: '.5rem 0', minWidth: '32px', width: '32px', height: '32px' }}> <NotificationActiveIcon /> </Button>
                                                        </div>
                                                    )
                                                }} />
                                            </div>
                                            <div>
                                                <Button disabled={!students.find(s => !s.present && !s.notified)} appearance="default" fullWidth accentColor={'#FDB323'} onClick={async () => {
                                                    let absents = students.filter(s => !s.present && !s.notified).map(s => s.id)
                                                    await notifyAbsents(absents)
                                                }} style={{ padding: '.5rem', fontSize: '1rem' }}> <NotificationActiveIcon /> NOTIFICA TUTTI</Button>
                                            </div>
                                        </div>
                                    }
                                    {listMode === 'waiting' &&
                                        <div className={styles.waiting}>
                                            <div className={styles.waitingHeader}>
                                                <div className={typo.subheadline}>In attesa</div>
                                                <div className={typo.subheadline}>{waitingList.length}</div>
                                            </div>
                                            <div className={styles.waitingParticipants}>
                                                <Each of={waitingList} render={(participant, index) => {
                                                    const colorIndex = (index) % palette.length
                                                    const color = palette[colorIndex]
                                                    return (
                                                        <div className={styles.waitingParticipant}>
                                                            {participant.picture &&
                                                                <img src={participant.picture} alt="" className={styles.waitingAvatar} />
                                                            }
                                                            {!participant.picture &&
                                                                <div style={{ backgroundColor: color }} className={styles.waitingAvatar}>{getInitials(participant.name)}</div>
                                                            }
                                                            {participant.name}
                                                            <div style={{ display: 'flex', flex: 1 }} />
                                                            {roomInfo.privacy === 'private' &&
                                                                <Button style={{ padding: '0.5rem' }} onClick={() => {
                                                                    grantAccess(participant.sessionId)
                                                                }}>ACCETTA</Button>
                                                            }
                                                        </div>
                                                    )
                                                }} />
                                            </div>
                                            {/* {roomInfo.privacy === 'private' &&
                                        <Button fullWidth onClick={unlockAndAccept} style={{ padding: '.5rem', fontSize: '1rem' }}>SBLOCCA</Button>
                                    } */}
                                        </div>
                                    }
                                    {listMode === 'screen_sharing_requests' &&
                                        <div className={styles.presents}>
                                            <div className={styles.presentsHeader}>
                                                <div className={typo.subheadline}>Richieste di condivisione</div>
                                                <div className={typo.subheadline}>{screenSharingRequests.length}</div>
                                            </div>
                                            <div className={typo.caption}>
                                                Accetta o rifiuta le richieste di condivisione dello schermo. Potrai interrompere le condivisioni accettate dal menù 'presenti'.
                                            </div>
                                            <div className={styles.presentsParticipants}>
                                                <Each of={screenSharingRequests.sort((a, b) => {
                                                    return a.user?.surname - b.user?.surname
                                                })} render={(request, index) => {
                                                    return (
                                                        <div className={styles.screenSharingRequest}>
                                                            <img src={request.user.picture ?? UserPlaceholder} alt="" className={styles.requestAvatar} />
                                                            <div className={styles.requestName}>{`${request.user.surname} ${request.user.name}`}</div>

                                                            <TooltipWrapper text="Rifiuta">
                                                                <div className={styles.declineButton} onClick={() => {
                                                                    websocket.sendMessage({
                                                                        type: 'screen_sharing_declined',
                                                                        sender_type: 'teacher',
                                                                        sessionId: request.sessionId
                                                                    })
                                                                    onScreenSharingRequestChange(request.sessionId)
                                                                }}>
                                                                    <CloseIcon className={styles.requestIcon} />
                                                                </div>
                                                            </TooltipWrapper>

                                                            <TooltipWrapper text="Accetta">
                                                                <div className={styles.acceptButton} onClick={() => {
                                                                    websocket.sendMessage({
                                                                        type: 'screen_sharing_accepted',
                                                                        sender_type: 'teacher',
                                                                        sessionId: request.sessionId
                                                                    })
                                                                    onScreenSharingRequestChange(request.sessionId)
                                                                }}>
                                                                    <CheckIcon className={styles.requestIcon} />
                                                                </div>
                                                            </TooltipWrapper>

                                                            <TooltipWrapper text="Accetta e Fissa">
                                                                <div className={styles.acceptPinButton} onClick={() => {
                                                                    websocket.sendMessage({
                                                                        type: 'screen_sharing_accepted',
                                                                        sender_type: 'teacher',
                                                                        sessionId: request.sessionId
                                                                    })
                                                                    onScreenSharingRequestChange(request.sessionId)

                                                                    if (!pinned?.includes(request.sessionId) && pinned?.length < 4) {
                                                                        setPinned([...pinned, request.sessionId])
                                                                        call.setMeetingSessionData({ pinned: [...pinned, request.sessionId] })
                                                                        setSpotlighted([...spotlighted, { session_id: request.sessionId, pinned: true }])
                                                                    }
                                                                    else {
                                                                        setPinned(pinned.filter(p => p !== request.sessionId))
                                                                        call.setMeetingSessionData({ pinned: pinned.filter(p => p !== request.sessionId) })
                                                                        setSpotlighted(spotlighted.map(s => {
                                                                            return { session_id: s.session_id, pinned: s.session_id === request.sessionId ? false : s.pinned }
                                                                        }))

                                                                    }

                                                                }}>
                                                                    <PinIcon className={styles.requestIcon} />
                                                                </div>
                                                            </TooltipWrapper>
                                                        </div>
                                                    )
                                                }} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </>
                    }

                </div>

                <div className={`${styles.participants} ${!showParticipants ? styles.close : ''}`}>
                    <div className={styles.participantsTrack}>
                        <DailyParticipant
                            key={localSessionId}
                            pinned={pinned}
                            isLocal={true}
                            muted={true}
                            session_id={localSessionId}
                            style={{ backgroundColor: 'black' }}
                            onClick={() => {
                                if (spotlighted && spotlighted.length < 4 && !spotlighted.find(s => s.session_id === localSessionId)) {
                                    setSpotlighted([...spotlighted, { session_id: localSessionId, pinned: false }])
                                }
                            }}
                            onPin={() => {
                                if (!pinned?.includes(localSessionId) && pinned?.length < 4) {
                                    setPinned([...pinned, localSessionId])
                                    call.setMeetingSessionData({ pinned: [...pinned, localSessionId] })
                                    setSpotlighted([...spotlighted, { session_id: localSessionId, pinned: true }])
                                }
                                else {
                                    setPinned(pinned.filter(p => p !== localSessionId))
                                    call.setMeetingSessionData({ pinned: pinned.filter(p => p !== localSessionId) })
                                    setSpotlighted(spotlighted.map(s => {
                                        return { session_id: s.session_id, pinned: s.session_id === localSessionId ? false : s.pinned }
                                    }))
                                }
                            }}
                        />
                        <Each of={sortedParticipants} render={(p, index) => {
                            return (
                                <DailyParticipant
                                    key={p}
                                    pinned={pinned}
                                    muted={muted}
                                    session_id={p}
                                    onClick={() => {
                                        if (spotlighted && spotlighted.length < 4 && !spotlighted.find(s => s.session_id === p)) {
                                            setSpotlighted([...spotlighted, { session_id: p, pinned: false }])
                                        }
                                    }}
                                    notifications={chatNotifications}
                                    onHandRaised={(name) => {
                                        context.setNotificationBanner({
                                            title: `${name} ha alzato la mano ✋`,
                                            body: "Clicca qui e apri il menù utenti.",
                                            onClick: () => {
                                                setShowParticipants(true)
                                            }
                                        })
                                    }}
                                    onPin={(session_id) => {
                                        if (!pinned?.includes(session_id) && pinned?.length < 4) {
                                            setPinned([...pinned, session_id])
                                            call.setMeetingSessionData({ pinned: [...pinned, session_id] })
                                            setSpotlighted([...spotlighted, { session_id: session_id, pinned: true }])
                                        }
                                        else {
                                            setPinned(pinned.filter(p => p !== session_id))
                                            call.setMeetingSessionData({ pinned: pinned.filter(p => p !== session_id) })
                                            setSpotlighted(spotlighted.map(s => {
                                                return { session_id: s.session_id, pinned: s.session_id === session_id ? false : s.pinned }
                                            }))

                                        }
                                    }}
                                />
                            )
                        }} />
                    </div>
                </div>

                <DailyControls
                    localSessionId={localSessionId}
                    showParticipants={showParticipants}
                    onShowParticipantsChange={() => {
                        setShowParticipants(!showParticipants)
                    }}
                    fullScreen={fullScreen}
                    onFullScreenChange={() => {
                        fullScreen ? fullScreenHandle.exit() : fullScreenHandle.enter()
                        setFullScreen(!fullScreen)
                    }}
                    whiteboard={whiteboard.show}
                    onWhiteboardChange={() => {
                        onWhiteboardChange(localSessionId, whiteboardController)
                    }}
                    chat={chat}
                    onChatChange={() => {
                        if (!chat) {
                            setUnread(0)
                        }
                        setChat(!chat)
                    }}
                    unread={unread}
                    handsRaised={handRaisedParticipants.length}
                    waitingParticipants={waitingParticipants}
                    overlayMenu={overlayMenu}
                    onOverlayMenuChange={(mode) => {
                        let newOverlayState = { open: false, mode: null }
                        if (overlayMenu.open) {
                            if (overlayMenu.mode !== mode) {
                                newOverlayState.mode = mode
                                newOverlayState.open = true
                            }
                            else {
                                newOverlayState.open = false
                            }
                        } else {
                            newOverlayState.mode = mode;
                            newOverlayState.open = true
                        }
                        setOverlayMenu(newOverlayState)
                    }}
                    pdf={file}
                    onPdfChange={(value) => {
                        setFile(value)
                    }}
                    muted={muted}
                    onMutedChange={(value) => {
                        setMuted(value)
                    }}
                    screenSharingRequests={screenSharingRequests}
                />
            </div>
            <AlertDialog
                open={endingCall}
                title={`Terminare la lezione ?`}
                text={`Tutti i partecipanti saranno rimossi. Se hai sbloccato la stanza, questa sarà bloccata nuovamente.`}
                onClose={() => {
                    setEndingCall(false)
                }}
                actions={[
                    {
                        label: "Termina",
                        onClick: async () => {
                            let updateList = {};
                            for (let id in call.participants()) {
                                if (id === 'local') {
                                    continue;
                                }
                                updateList[id] = { eject: true };
                            }
                            await lockRoom(true)
                            call.updateParticipants(updateList);
                            call.leave()
                            setEndingCall(false)
                        }
                    }
                ]}
            />
        </FullScreen >
    )
}

export default DailyRoom
