import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function useBeforeUnload(message, when = true) {
    const navigate = useNavigate();

    useEffect(() => {
        if (!when) return;

        const handleBeforeUnload = (event) => {
            event.returnValue = message; // Imposta il messaggio di conferma
            return message; // Alcuni browser richiedono anche questa restituzione
        };

        const handlePopState = (event) => {
            // Blocca la navigazione avanti/indietro
            const confirmLeave = window.confirm(message);
            if (confirmLeave) {
                // Se l'utente conferma, consenti la navigazione
                return;
            }
            // Se l'utente rifiuta, impedisci la navigazione
            event.preventDefault();
            navigate(0); // Reset della pagina, puoi personalizzare la logica di navigazione
        };

        // Aggiungi l'evento beforeunload
        window.addEventListener('beforeunload', handleBeforeUnload);

        // Aggiungi l'evento popstate per intercettare la navigazione avanti/indietro
        window.addEventListener('popstate', handlePopState);

        // Cleanup: rimuovi gli event listener quando il componente viene smontato
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [message, when, navigate]);

}

export default useBeforeUnload;
