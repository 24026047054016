import { useDaily, useDailyError, useDevices, useMediaTrack, useParticipantProperty, useRoom, useScreenShare } from "@daily-co/daily-react";
import { useEffect, useState } from "react";
import { ReactComponent as ListIcon } from "../../assets/images/icons/ic-list.svg";
import { ReactComponent as CameraOffIcon } from "../../assets/images/icons/ic-camera-off.svg";
import { ReactComponent as CameraIcon } from "../../assets/images/icons/ic-camera.svg";
import { ReactComponent as ChatIconOff } from "../../assets/images/icons/ic-chat.svg";
import { ReactComponent as CheckIcon } from "../../assets/images/icons/ic-check.svg";
import { ReactComponent as WhiteboardIcon } from "../../assets/images/icons/ic-hand-draw.svg";
import { ReactComponent as MicOffIcon } from "../../assets/images/icons/ic-mic-off.svg";
import { ReactComponent as MicIcon } from "../../assets/images/icons/ic-mic.svg";
import { ReactComponent as FullScreenIcon } from "../../assets/images/icons/ic-screen-maximize.svg";
import { ReactComponent as FullScreenOffIcon } from "../../assets/images/icons/ic-screen-minimize.svg";
import { ReactComponent as ScreenIcon } from "../../assets/images/icons/ic-screenshare.svg";
import { ReactComponent as ScreenOffIcon } from "../../assets/images/icons/ic-screenshare-stop.svg";
import { ReactComponent as GearIcon } from "../../assets/images/icons/ic-settings.svg";
import { ReactComponent as UsersIcon } from "../../assets/images/icons/ic-users.svg";
import { ReactComponent as DocumentIcon } from "../../assets/images/icons/ic-document.svg";
import { ReactComponent as SpeakerIcon } from "../../assets/images/icons/ic-speaker.svg"
import { ReactComponent as SpeakerIcon0 } from "../../assets/images/icons/ic-speaker0.svg"
import { ReactComponent as ScreenAudioIcon } from "../../assets/images/icons/ic-screen-audio.svg"
import { ReactComponent as ScreenAdioOffIcon } from "../../assets/images/icons/ic-screen-audio-off.svg"

import { Each } from "../../common/Each";
import useSpeechDetection from "../../common/hooks/useSpeechDetection";
import { DialogStatus } from "../../enums";
import AlertDialog from "../dialogs/AlertDialog";
import DailyControl from "./DailyControl";
import styles from "./DailyControls.module.css";
import DailySpinner from "./DailySpinner";
import { isMobile } from "react-device-detect";


const DailyControls = ({
    localSessionId,
    showParticipants,
    onShowParticipantsChange,
    fullScreen,
    onFullScreenChange,
    whiteboard,
    onWhiteboardChange,
    chat,
    onChatChange,
    unread,
    handsRaised,
    waitingParticipants,
    overlayMenu,
    onOverlayMenuChange,
    pdf,
    onPdfChange,
    muted,
    onMutedChange,
    screenSharingRequests }) => {

    const call = useDaily()
    const room = useRoom()
    const localParticipant = useParticipantProperty(localSessionId, ['audio', 'video', 'user_name', 'userData', 'audioTrack', 'tracks'])
    const devices = useDevices()
    const { isSharingScreen, startScreenShare, stopScreenShare } = useScreenShare()
    const audioTrack = useMediaTrack(localSessionId, 'audio')
    const [loadingMic, setLoadingMic] = useState(null)
    const [loadingCam, setLoadingCam] = useState(null)
    const [loadingSpeaker, setLoadingSpeaker] = useState(null)

    const audioLevel = useSpeechDetection(audioTrack.track)
    const [permissionError, setPermissionError] = useState({ show: false, title: 'Errore', message: '' })
    const { nonFatalError } = useDailyError();

    useEffect(() => {
        if (nonFatalError && nonFatalError.type) {
            if (nonFatalError.type === 'screen-share-error') {
                setPermissionError({ show: true, title: 'Permessi Condivisione Schermo', message: `Sembra che tu non abbia acconsentito alla condivisione schermo. Per favore consenti i permessi dalle impostazioni del tuo browser e aggiorna la pagina.` })
            }
        }
    }, [nonFatalError])

    async function checkPermissions(type) {
        try {
            if (!['camera', 'microphone'].includes(type)) {
                throw new Error("Wrong permission type.")
            }

            const permission = await navigator.permissions.query({ name: type });
            const isGranted = permission.state === 'granted';

            if (!isGranted) {
                await requestPermissions()
            }

        } catch (error) {
            console.error('Error checking permissions:', error);
        }
    }

    async function requestPermissions() {
        try {
            // Richiede l'accesso alla fotocamera e al microfono
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            console.log('Permessi concessi per fotocamera e microfono');
        } catch (error) {
            console.error('Permessi negati o errore nel richiedere i permessi:', error);
            setPermissionError({ show: true, title: 'Permessi Fotocamera e Microfono', message: `Sembra che tu non abbia acconsentito l'accesso alla fotocamera e al microfono. Per favore consenti i permessi dalle impostazioni del tuo browser e aggiorna la pagina.` })
            throw error
        }
    }

    const handleFileInputChange = (event) => {
        const file = event.target.files[0];
        onPdfChange(file)
        let fileInput = document.querySelector('#pdf-input')
        document.body.removeChild(fileInput);
    };

    return (
        <>
            <div className={styles.container}>
                <DailyControl
                    id="microphones"
                    active={localParticipant[0]}
                    icons={{
                        normal: <MicOffIcon className={styles.toolIcon} />,
                        active: <MicIcon className={styles.toolIcon} />
                    }}
                    onClick={() => {
                        checkPermissions('microphone')
                        call.setLocalAudio(!localParticipant[0])
                    }}
                    hasOption={devices.microphones.length > 1}
                    level={localParticipant[0] ? audioLevel : 0}
                >
                    <div className={styles.devices}>
                        <Each of={devices.microphones} render={(microphone) => (
                            <div className={styles.device} onClick={async (e) => {
                                e.stopPropagation()
                                setLoadingMic(microphone.device.deviceId)
                                await devices.setMicrophone(microphone.device.deviceId)
                                setLoadingMic(null)
                            }}>
                                <div className={styles.deviceSelected}>
                                    {microphone.selected &&
                                        <CheckIcon style={{ width: '12px', height: '12px' }} />
                                    }
                                    {loadingMic === microphone.device.deviceId &&
                                        <DailySpinner />
                                    }
                                </div>
                                <div className={styles.deviceLabel}>
                                    {microphone.device.label}
                                </div>
                            </div>
                        )} />
                    </div>

                </DailyControl>

                <DailyControl
                    id="cameras"
                    active={localParticipant[1]}
                    icons={{
                        normal: <CameraOffIcon className={styles.toolIcon} />,
                        active: <CameraIcon className={styles.toolIcon} />
                    }}
                    onClick={() => {
                        checkPermissions('camera')
                        call.setLocalVideo(!localParticipant[1])
                    }}
                    hasOption={devices.cameras.length > 1}
                >
                    <div className={styles.devices}>
                        <Each of={devices.cameras} render={(camera) => (
                            <div className={styles.device} onClick={async (e) => {
                                e.stopPropagation()
                                setLoadingCam(camera.device.deviceId)
                                await devices.setCamera(camera.device.deviceId)
                                setLoadingCam(null)
                            }}>
                                <div className={styles.deviceSelected}>
                                    {camera.selected &&
                                        <CheckIcon style={{ width: '12px', height: '12px' }} />
                                    }
                                    {loadingCam === camera.device.deviceId &&
                                        <DailySpinner />
                                    }
                                </div>
                                <div className={styles.deviceLabel}>
                                    {camera.device.label}
                                </div>
                            </div>
                        )} />
                    </div>
                </DailyControl>

                <DailyControl
                    id="speakers"
                    active={!muted}
                    icons={{
                        normal: <SpeakerIcon0 className={styles.toolIcon} />,
                        active: <SpeakerIcon className={styles.toolIcon} style={{ color: 'var(--primary)' }} />
                    }}
                    onClick={() => {
                        // call.setLocalVideo(!localParticipant[1])
                        onMutedChange(!muted)
                    }}
                    hasOption={devices.speakers.length > 1}
                >
                    <div className={styles.devices}>
                        <Each of={devices.speakers} render={(speaker) => (
                            <div className={styles.device} onClick={async (e) => {
                                e.stopPropagation()
                                setLoadingSpeaker(speaker.device.deviceId)
                                await devices.setSpeaker(speaker.device.deviceId)

                                setLoadingSpeaker(null)
                            }}>
                                <div className={styles.deviceSelected}>
                                    {speaker.selected && !loadingSpeaker &&
                                        <CheckIcon style={{ width: '12px', height: '12px' }} />
                                    }
                                    {loadingSpeaker === speaker.device.deviceId &&
                                        <DailySpinner />
                                    }
                                </div>
                                <div className={styles.deviceLabel}>
                                    {speaker.device.label}
                                </div>
                            </div>
                        )} />
                    </div>
                </DailyControl>

                {call.meetingState() === "joined-meeting" &&
                    <>
                        <DailyControl
                            active={false}
                            icons={{
                                normal: <UsersIcon className={`${styles.toolIcon}`} />,
                                active: <UsersIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={() => {
                                onShowParticipantsChange()
                            }}
                            tooltip={'Utenti'}
                            badgeNumber={handsRaised}
                        />

                        {/* {waitingParticipants.length > 0 && */}
                        <DailyControl
                            active={overlayMenu.open && overlayMenu.mode === 'list'}
                            icons={{
                                normal: <ListIcon className={`${styles.toolIcon}`} />,
                                active: <ListIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={() => {
                                onOverlayMenuChange('list')
                            }}
                            tooltip={`Lista`}
                            badgeNumber={waitingParticipants.length + screenSharingRequests.length}
                        />
                        {/* } */}

                        <DailyControl
                            active={chat}
                            icons={{
                                normal: <ChatIconOff className={`${styles.toolIcon}`} />,
                                active: <ChatIconOff className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={() => {
                                onChatChange()
                            }}
                            tooltip="Chat"
                            badgeNumber={unread}
                        />

                        {!isMobile &&
                            <DailyControl
                                id="screenshare"
                                active={isSharingScreen}
                                icons={{
                                    normal: <ScreenIcon className={`${styles.toolIcon}`} />,
                                    active: <ScreenOffIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => {
                                    isSharingScreen ? stopScreenShare() : startScreenShare({ screenAudio: true })
                                }}
                                // tooltip={isSharingScreen ? "Interrompi condivisione" : "Condividi schermo"}
                                hasOption={isSharingScreen}
                            >
                                <div className={styles.toggleOption}>
                                    {!['off', 'playable'].includes(localParticipant[5]?.screenAudio.state) &&
                                        <div className={styles.option}>
                                            Nessuna fonte audio<br></br>
                                            da condividere.
                                        </div>
                                    }
                                    {localParticipant[5]?.screenAudio.state === "off" &&
                                        <div className={styles.option} onClick={() => {
                                            call.updateScreenShare({ screenAudio: { enabled: true } })
                                        }}>
                                            <ScreenAudioIcon />
                                            Condividi Audio
                                        </div>
                                    }
                                    {localParticipant[5]?.screenAudio.state === 'playable' &&
                                        <div className={styles.option} onClick={() => {
                                            call.updateScreenShare({ screenAudio: { enabled: false } })
                                        }}>
                                            <ScreenAdioOffIcon style={{ color: '#F43A3A' }} />
                                            Interrompi Audio
                                        </div>
                                    }
                                </div>
                            </DailyControl>
                        }

                        {/* <DailyControl
                            active={whiteboard}
                            icons={{
                                normal: <WhiteboardIcon className={`${styles.toolIcon}`} />,
                                active: <WhiteboardIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={async () => {
                                onWhiteboardChange()
                            }}
                            tooltip={whiteboard ? "Chiudi" : 'Lavagna'}
                        /> */}

                        {!isMobile &&
                            <DailyControl
                                active={fullScreen}
                                icons={{
                                    normal: <FullScreenIcon className={`${styles.toolIcon}`} />,
                                    active: <FullScreenOffIcon className={`${styles.toolIcon} ${styles.active}`} />
                                }}
                                onClick={() => {
                                    onFullScreenChange()
                                }}
                                tooltip={fullScreen ? "Riduci" : "Espandi"}
                            />
                        }

                        {/* <DailyControl
                            active={pdf}
                            icons={{
                                normal: <DocumentIcon className={`${styles.toolIcon}`} />,
                                active: <DocumentIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={async () => {
                                if (pdf) {
                                    onPdfChange(null)
                                }
                                else {
                                    const input = document.createElement('input');
                                    input.type = 'file';
                                    input.id = "pdf-input"
                                    input.style.display = 'none';
                                    input.accept = 'application/pdf';
                                    input.addEventListener('change', handleFileInputChange);
                                    document.body.appendChild(input);
                                    input.click();
                                }
                            }}
                            tooltip={pdf ? "Chiudi" : 'PDF'}
                        /> */}


                        <DailyControl
                            active={overlayMenu.open && overlayMenu.mode === 'settings'}
                            icons={{
                                normal: <GearIcon className={`${styles.toolIcon}`} />,
                                active: <GearIcon className={`${styles.toolIcon} ${styles.active}`} />
                            }}
                            onClick={() => { onOverlayMenuChange('settings') }}
                            tooltip={'Impostazioni'}
                        />
                    </>
                }
            </div>

            <AlertDialog
                open={permissionError.show}
                errorMessage={permissionError.title}
                text={permissionError.message}
                status={DialogStatus.Error}
                onClose={() => {
                    setPermissionError({ show: false, title: 'Errore', message: '' })
                }}
            />
        </>
    )

}

export default DailyControls