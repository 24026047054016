import { useParams } from "react-router-dom"
import DailyChat from "./DailyChat"
import { useContext, useEffect, useState } from "react"
import styles from "./DailyChatPopup.module.css"
import MainContext from "../../common/MainContext"
import api from "../../api"
import typo from "../../typography.module.css"
import { formatDateV2, formatTimeV2 } from "../../utils"
import { useDaily } from "@daily-co/daily-react"
import Daily from "@daily-co/daily-js"

const DailyChatPopup = () => {

    const { roomName } = useParams()
    const [token, setToken] = useState(null)
    const [sounds, setSounds] = useState(false)
    const [notifications, setNotifications] = useState(false)
    const context = useContext(MainContext)
    const [lesson, setLesson] = useState(null)
    const [error, setError] = useState(null)
    const [call, setCall] = useState(null)
    const [color, setColor] = useState(null)

    const joinCall = async (tokenString) => {
        try {
            const call = Daily.createCallObject({ url: `https://startingfinance.daily.co/${roomName}`, token: tokenString, allowMultipleCallInstances: true })
            await call.join()
            call.setUserName(`${context.user?.name} ${context.user?.surname} - Chat`)
            call.setUserData({ ...context.user, color: context.user?.color ?? color, is_chat: true })
            setCall(call)
        }
        catch (e) {
            console.error(e)
            setError(e)
        }
    }

    useEffect(() => {
        if (token) {
            joinCall(token)
        }
    }, [token])

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        setToken(queryParams.get('token'))
        setNotifications(queryParams.get('notifications'))
        setSounds(queryParams.get('sounds'))
        setColor(queryParams.get('color'))
    }, [])

    useEffect(() => {

        const getLesson = async (room_name) => {
            try {
                let lesson = await api.get(`/teacher/lessons/${room_name}`)
                setLesson(lesson)
            }
            catch (e) {
                console.error(e)
            }
        }

        getLesson(roomName)

    }, [roomName])

    return (
        <div className={styles.container}>
            {context.user && lesson && !lesson.ended_at && call &&
                <DailyChat call={call} open={true} lesson_id={lesson.id} sounds={sounds} notifications={notifications} popup={true} />
            }
            {lesson && lesson.ended_at &&
                <div className={styles.ended}>
                    <div className={typo.title}>Questa lezione è terminata</div>
                    <div>La lezione è terminata il {formatDateV2(lesson.ended_at)} alle {formatTimeV2(lesson.ended_at)} e la sua chat non è più disponibile.
                        Se hai necessità di recuperare informazioni importanti dalla chat della lezione ti preghiamo di contattare il supporto.</div>
                </div>
            }
            {error &&
                <div className={styles.ended}>
                    Errore: {error.message}
                </div>
            }
        </div>
    )
}

export default DailyChatPopup