import { useContext, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import api from "../../api";
import { ReactComponent as BookIconActive } from "../../assets/images/icons/ic-path-active.svg";
import { ReactComponent as BookIcon } from "../../assets/images/icons/ic-path.svg";
import { ReactComponent as ExerciseIconActive } from "../../assets/images/icons/ic-exercise-active.svg";
import { ReactComponent as ExerciseIcon } from "../../assets/images/icons/ic-exercise-inactive.svg";
import { ReactComponent as ChatIconActive } from "../../assets/images/icons/ic-chat-active.svg";
import { ReactComponent as ChatIcon } from "../../assets/images/icons/ic-chat.svg";
import { ReactComponent as DashboardIconActive } from "../../assets/images/icons/ic-dashboard-active.svg";
import { ReactComponent as DashboardIcon } from "../../assets/images/icons/ic-dashboard-inactive.svg";
import { ReactComponent as MaterialsIconActive } from "../../assets/images/icons/ic-files-active.svg";
import { ReactComponent as MaterialsIcon } from "../../assets/images/icons/ic-files-inactive.svg";
import { ReactComponent as LessonsIconActive } from "../../assets/images/icons/ic-lessons-active.svg";
import { ReactComponent as LessonsIcon } from "../../assets/images/icons/ic-lessons-inactive.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/images/icons/ic-minimize.svg";
import { ReactComponent as BellFillIcon } from "../../assets/images/icons/ic-notification-active.svg";
import { ReactComponent as BellIcon } from "../../assets/images/icons/ic-notification.svg";
import { ReactComponent as TestIconActive } from "../../assets/images/icons/ic-test-active.svg";
import { ReactComponent as TestIcon } from "../../assets/images/icons/ic-test-inactive.svg";
import { ReactComponent as LogoMini } from "../../assets/images/velv-minimized.svg";
import { ReactComponent as Logo } from "../../assets/images/velv-black.svg";
import MainContext from "../../common/MainContext";
import useWindowDimensions from "../../common/hooks/useWindowDimensions";

import Hamburger from "./Hamburger";
import styles from "./SideNav.module.css";
import Skeleton from "../Skeleton";

const SideNav = ({ onChange }) => {

    const { t } = useTranslation()
    const { width } = useWindowDimensions()
    const location = useLocation()
    const context = useContext(MainContext)

    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(width > 767)
    const noSideNavRoutes = useMemo(() => { return ["/login", "/changePassword"] }, [])

    useEffect(() => {
        if (context.scrollRef?.current) {
            context.scrollRef?.current.scrollIntoView()
        }
    }, [location])

    useEffect(() => {
        if (location.pathname !== "/changePassword" && context?.user?.force_change_password) {
            navigate("/changePassword")
        }
    }, [location, context, navigate])

    useEffect(() => {
        return () => {
            document.body.style.overflow = '';
        }
    }, [])

    useEffect(() => {
        setIsOpen(width > 1120)
    }, [width])

    useEffect(() => {
        if (isOpen && width <= 768) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = '';
        }
    }, [isOpen, width])

    useEffect(() => {
        async function getUser() {
            try {
                let user = await api.get("/teacher/")
                if (user) {
                    context.setUser(user)
                }
            }
            catch (error) {
                console.error(error)
            }
        }
        if (!context.user) {
            getUser()
        }
    }, [context])

    function toggleOpen() {
        setIsOpen(!isOpen)

        if (onChange) {
            onChange()
        }
    }

    function closeSideNav() {
        if (width <= 768) {
            setIsOpen(false)
        }
    }

    function getIcon(page) {
        if (page === "" && location.pathname === "/") {
            return <DashboardIconActive style={{ width: '20px', height: '20px' }}></DashboardIconActive>
        } else if (page === "") {
            return <DashboardIcon style={{ width: '20px', height: '20px' }}></DashboardIcon>
        }

        if (location.pathname && location.pathname.includes(`/${page}`)) {
            switch (page) {
                case '':
                    return <DashboardIconActive style={{ width: '20px', height: '20px' }}></DashboardIconActive>
                case 'courses':
                    return <BookIconActive style={{ width: '20px', height: '20px' }}></BookIconActive>
                case 'lessons':
                    return <LessonsIconActive style={{ width: '20px', height: '20px' }}></LessonsIconActive>
                case 'materials':
                    return <MaterialsIconActive style={{ width: '20px', height: '20px' }}></MaterialsIconActive>
                case 'tests':
                    return <TestIconActive style={{ width: '20px', height: '20px' }}></TestIconActive>
                case 'messages':
                    return <ChatIconActive style={{ width: '20px', height: '20px' }}></ChatIconActive>
                case 'exercises':
                    return <ExerciseIconActive style={{ width: '20px', height: '20px' }}></ExerciseIconActive>
                default: return
            }
        }
        else {
            switch (page) {
                case '':
                    return <DashboardIcon style={{ width: '20px', height: '20px' }}></DashboardIcon>
                case 'courses':
                    return <BookIcon style={{ width: '20px', height: '20px' }}></BookIcon>
                case 'lessons':
                    return <LessonsIcon style={{ width: '20px', height: '20px' }}></LessonsIcon>
                case 'materials':
                    return <MaterialsIcon style={{ width: '20px', height: '20px' }}></MaterialsIcon>
                case 'tests':
                    return <TestIcon style={{ width: '20px', height: '20px' }}></TestIcon>
                case 'messages':
                    return <ChatIcon style={{ width: '20px', height: '20px' }}></ChatIcon>
                case 'exercises':
                    return <ExerciseIcon style={{ width: '20px', height: '20px' }}></ExerciseIcon>
                default: return
            }
        }
    }

    function navigateTo(page) {
            navigate(page)
    }

    return (
        <>
            {
                noSideNavRoutes.includes(location.pathname) === false && (
                    <div className={styles.sidenav + ' ' + (isOpen ? styles.open : styles.close)} id="sidenav">
                        <div className={styles.header}>

                            {width > 768 && !isOpen &&
                                <LogoMini className={styles.logoMini}  onClick={() => { navigate('/') }}></LogoMini>
                            }

                            {(isOpen || width <= 768) &&
                                <>
                                    <Logo className={styles.logo} onClick={() => { navigate('/') }}></Logo>
                                    <div className={styles.spacer}></div>
                                </>
                            }

                            {width <= 768 &&
                                <>
                                    <div className={styles.iconButtons}>
                                        <button className={styles.iconButton} onClick={() => {
                                            closeSideNav()
                                            navigate("/notifications")
                                        }}>
                                            {context.notifications?.metadata.unread === 0 &&
                                                    <BellIcon />
                                            }
                                            {context.notifications?.metadata.unread > 0 &&
                                                <>
                                                    <BellFillIcon />
                                                    <div className={styles.badge}>
                                                        {context.notifications.metadata.unread}
                                                    </div>
                                                </>
                                            }
                                        </button>
                                    </div>
                                    <div className={styles.hamburger}>
                                        <Hamburger isOpen={isOpen} onChange={(event) => { setIsOpen(event.target.checked) }}></Hamburger>
                                    </div>
                                </>
                            }

                            {width > 768 &&
                                <button className={styles.closeButton} onClick={toggleOpen} aria-label="close-sidebar">
                                    <MinimizeIcon></MinimizeIcon>
                                </button>
                            }
                        </div>
                        <div className={`${styles.content}`}>
                            <button className={[styles.navLink, location.pathname === '/' ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/')
                                }}>
                                {getIcon('')}
                                {isOpen &&
                                    <>
                                        Dashboard
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.includes('/courses') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/courses')
                                }}>
                                {getIcon('courses')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.courses')}
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.includes('/lessons') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/lessons')
                                }}>
                                {getIcon('lessons')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.lessons')}
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.includes('/materials') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/materials')
                                }}>
                                {getIcon('materials')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.materials')}
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.includes('/tests') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/tests')
                                }}>
                                {getIcon('tests')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.tests')}
                                    </>
                                }
                            </button>
                            <button className={[styles.navLink, location.pathname.includes('/exercises') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigate('/exercises')
                                }}>
                                {getIcon('exercises')}
                                {isOpen &&
                                    <>
                                        {t('sidenav.exercises')}
                                    </>
                                }
                            </button>
                            <div className={styles.spacer}></div>


                            <button
                                className={[styles.bottomNavLink, location.pathname.includes('/messages') ? styles.active : ''].join(' ')}
                                onClick={() => {
                                    closeSideNav()
                                    navigateTo('/messages')
                                }}>
                                {getIcon('messages', context.un)}
                                {context.unreadMessages > 0 &&
                                    <div className={styles.iconBadge}>{context.unreadMessages}</div>
                                }
                                {isOpen &&
                                    <>
                                        {t('sidenav.messages')}
                                    </>
                                }
                            </button>
                            <button className={styles.userButton} onClick={() => {
                                closeSideNav()
                                navigateTo("/profile")
                            }}>
                                <div className={styles.userContainer}>
                                    {
                                        context.user &&
                                        <>
                                            <img src={context.user.picture} alt="avatar" className={styles.userAvatar} />
                                            <div className={styles.userName}>{`${context.user.name} ${context.user.surname}`}</div>
                                        </>
                                    }
                                    {
                                        !context.user &&
                                        <>
                                            <Skeleton type="circle" width="32px" height="32px" />
                                            <Skeleton type="rect" width="144px" height="1rem" borderRadius="12px" />
                                        </>
                                    }
                                </div>
                            </button>
                        </div>
                    </div>
                )
            }
        </>

    )

}

export default SideNav
